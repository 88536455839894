module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/vr-logo-themed.svg","name":"Metadataverktyget RUT","short_name":"RUT","start_url":"/","background_color":"#ffffff","theme_color":"#ce2b20","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0b90a0974da0079d48d59e476e25c82d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Start","exclude":["/dev-404-page/","/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/metadatakatalog","crumbLabel":"Metadatakatalog"},{"pathname":"/metadatakatalog/register","crumbLabel":"Register"},{"pathname":"/metadatakatalog/register/jamfor","crumbLabel":"Jämför"}],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-K9ZHS5N","cookieName":"gdpr-google-gtag","transportUrl":"https://tm.registerforskning.se","firstPartyCollection":true},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
