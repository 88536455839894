import colors, { vrColorPalette } from 'styles/colors';
import { isInBrowser }  from 'util/jsEnvironment';

const message = `

┌────────────────────────────────┐
│                                │
│    ██████╗ ██╗   ██╗████████╗  │
│    ██╔══██╗██║   ██║╚══██╔══╝  │
│    ██████╔╝██║   ██║   ██║     │
│    ██╔══██╗██║   ██║   ██║     │
│    ██║  ██║╚██████╔╝   ██║     │
│    ╚═╝  ╚═╝ ╚═════╝    ╚═╝     │
│                                │
│  -= Register Utilizer Tool =-  │
│                                │
│ En tjänst från Vetenskapsrådet │
└────────────────────────────────┘

`;

const style = `
  color: ${colors.brandColorPrimary};
  background: ${vrColorPalette.primaries.blue};
  font-size: 16px;
  line-height: 18px;
`;

export const printRutMessage = () => {
  if(isInBrowser) {
    console.log('%c%s', style, message);
  }
};
