import 'rut-components/dist/css/semantic-ui/semantic-ui-atlas-grotesk-web.css';
import './src/styles/global.css';
import { printRutMessage } from './src/components/rutConsoleMessage';

let messageHasBeenPrinted = false;

// This code runs after all other code in the browser
export const onRouteUpdate = () => {
  if (!messageHasBeenPrinted) {
    printRutMessage();
    messageHasBeenPrinted = true;
  }
};
